import revive_payload_client_FfVQLO2A3j from "/home/skorn/git.root/cf-web-static-nuxt-costeri/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_2nnz65euw6q43kb7psyr5rboja/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Te88U0EuCw from "/home/skorn/git.root/cf-web-static-nuxt-costeri/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_2nnz65euw6q43kb7psyr5rboja/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_pvCpVlVEVb from "/home/skorn/git.root/cf-web-static-nuxt-costeri/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_2nnz65euw6q43kb7psyr5rboja/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_C6S7DlhsJb from "/home/skorn/git.root/cf-web-static-nuxt-costeri/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.21.0_vite@5.4.2_@types+node@22.5.0_terser@5.3_l72rr2kwcl473wawgjnhhyqh5y/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import navigation_repaint_client_SLVLwD9B7T from "/home/skorn/git.root/cf-web-static-nuxt-costeri/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_2nnz65euw6q43kb7psyr5rboja/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ii3wvmtncP from "/home/skorn/git.root/cf-web-static-nuxt-costeri/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_2nnz65euw6q43kb7psyr5rboja/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_XH59aSqAhA from "/home/skorn/git.root/cf-web-static-nuxt-costeri/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_2nnz65euw6q43kb7psyr5rboja/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/skorn/git.root/cf-web-static-nuxt-costeri/.nuxt/components.plugin.mjs";
import prefetch_client_5DrtbQOY91 from "/home/skorn/git.root/cf-web-static-nuxt-costeri/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_2nnz65euw6q43kb7psyr5rboja/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_GZ3U0YdskV from "/home/skorn/git.root/cf-web-static-nuxt-costeri/node_modules/.pnpm/@nuxt+icon@1.4.10_magicast@0.3.4_rollup@4.21.0_vite@5.4.2_@types+node@22.5.0_terser@5.31.6__vue@3.4.38_typescript@5.5.4_/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import titles_r1VuoAKLcK from "/home/skorn/git.root/cf-web-static-nuxt-costeri/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.19_h3@1.12.0_magicast@0.3.4_rollup@4.21.0_vite@5.4.2_@types+node@22.5.0__e6xzlbobxsocqdlhhnoya4irie/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_o27ILFd8uR from "/home/skorn/git.root/cf-web-static-nuxt-costeri/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.21.0_vite@5.4.2_@types+node@22.5.0_terser@_66kejfjqrxj3r4av3nhspjcbqi/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_AL6ehIqeHU from "/home/skorn/git.root/cf-web-static-nuxt-costeri/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.21.0_vite@5.4.2_@types+node@22.5.0_terser@_66kejfjqrxj3r4av3nhspjcbqi/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import vue_awesome_paginate_9MuQF1jtVP from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/plugins/vue-awesome-paginate.js";
import defaults_qIPElTTNki from "/home/skorn/git.root/cf-web-static-nuxt-costeri/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.19_h3@1.12.0_magicast@0.3.4_rollup@4.21.0_vite@5.4.2_@types+node@22.5.0__e6xzlbobxsocqdlhhnoya4irie/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_FfVQLO2A3j,
  unhead_Te88U0EuCw,
  router_pvCpVlVEVb,
  _0_siteConfig_C6S7DlhsJb,
  navigation_repaint_client_SLVLwD9B7T,
  check_outdated_build_client_ii3wvmtncP,
  chunk_reload_client_XH59aSqAhA,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5DrtbQOY91,
  plugin_GZ3U0YdskV,
  titles_r1VuoAKLcK,
  siteConfig_o27ILFd8uR,
  inferSeoMetaPlugin_AL6ehIqeHU,
  vue_awesome_paginate_9MuQF1jtVP,
  defaults_qIPElTTNki
]