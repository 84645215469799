import { default as indexk36Kdzm4r2Meta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/(download-bereich)/3e403811-1d25-490c-bde7/index.vue?macro=true";
import { default as wunsch_45traumreise_45anfrage_45erhaltenIeACYqrWoCMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/(forms)/wunsch-traumreise-anfrage-erhalten.vue?macro=true";
import { default as agbU8ATZlKcAmMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/(legal)/agb.vue?macro=true";
import { default as datenschutzkHFkt41CJUMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/(legal)/datenschutz.vue?macro=true";
import { default as haftungsausschlussvmVl26DXOiMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/(legal)/haftungsausschluss.vue?macro=true";
import { default as impressump8xuK3DSDLMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/(legal)/impressum.vue?macro=true";
import { default as nutzungsbedingungen3PFiw8tyXXMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/(legal)/nutzungsbedingungen.vue?macro=true";
import { default as ueberunsNmCQCi3d2QMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/(legal)/ueberuns.vue?macro=true";
import { default as _200WHwbGVZk44Meta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/(system)/200.vue?macro=true";
import { default as _404Wa308C1BwiMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/(system)/404.vue?macro=true";
import { default as errorl6SwRGGbDUMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/(system)/error.vue?macro=true";
import { default as statusDpEcC5jykEMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/(system)/status.vue?macro=true";
import { default as suchedYSaGUMmE0Meta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/ferienwohnungen/suche.vue?macro=true";
import { default as _91slug_93_45angeboteREDxkRHQGCMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/hotels/[slug]-angebote.vue?macro=true";
import { default as hotels_45zum_45verliebenXnJJLx1Fr6Meta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/hotels/hotels-zum-verlieben.vue?macro=true";
import { default as index9SRDJZzN3FMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/hotels/index.vue?macro=true";
import { default as indexPevFdO1h5RMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/index.vue?macro=true";
import { default as aida_45kreuzfahrten51789jhr4dMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-reederei/aida-kreuzfahrten.vue?macro=true";
import { default as carnival_45kreuzfahrtenYK5q1Z8qUjMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-reederei/carnival-kreuzfahrten.vue?macro=true";
import { default as costa_45kreuzfahrtenorMtjTsWIxMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-reederei/costa-kreuzfahrten.vue?macro=true";
import { default as explora_45journeys_45kreuzfahrtenBxSVq1qHJrMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-reederei/explora-journeys-kreuzfahrten.vue?macro=true";
import { default as msc_45kreuzfahrtenBgrahOi0fjMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-reederei/msc-kreuzfahrten.vue?macro=true";
import { default as ncl_45kreuzfahrtenp2e0QHgydaMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-reederei/ncl-kreuzfahrten.vue?macro=true";
import { default as aidablu_45kreuzfahrtenLNel0bCredMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-schiffe/aidablu-kreuzfahrten.vue?macro=true";
import { default as aidacosma_45kreuzfahrtenXDHl7rjpIdMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-schiffe/aidacosma-kreuzfahrten.vue?macro=true";
import { default as aidanova_45kreuzfahrtenRU9832QEFpMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-schiffe/aidanova-kreuzfahrten.vue?macro=true";
import { default as aidaperla_45kreuzfahrtenIazRxxLGqaMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-schiffe/aidaperla-kreuzfahrten.vue?macro=true";
import { default as aidaprima_45kreuzfahrtenuTshkk6K6ZMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-schiffe/aidaprima-kreuzfahrten.vue?macro=true";
import { default as costa_45smeralda_45kreuzfahrtencBmcxvhy0YMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-schiffe/costa-smeralda-kreuzfahrten.vue?macro=true";
import { default as explora_45i_45kreuzfahrtenYeoF2A8IcMMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-schiffe/explora-i-kreuzfahrten.vue?macro=true";
import { default as aida_45kanaren_45madeira_45ab_45gran_45canaria_45mit_45aidacosmai3HMOS2Fj0Meta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrten/aida-kanaren-madeira-ab-gran-canaria-mit-aidacosma.vue?macro=true";
import { default as aida_45karibische_45inseln_45ab_45barbados_45mit_45aidaperlaekEYbtEBBQMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrten/aida-karibische-inseln-ab-barbados-mit-aidaperla.vue?macro=true";
import { default as aida_45mauritius_45seychellen_45madagaskar_45mit_45aidabluUuv6Hr8BSOMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrten/aida-mauritius-seychellen-madagaskar-mit-aidablu.vue?macro=true";
import { default as aida_45orient_45ab_45dubai_45mit_45aidaprima3WDCCLFKf1Meta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrten/aida-orient-ab-dubai-mit-aidaprima.vue?macro=true";
import { default as costa_45mittelmeer_45kreuzfahrt_45mit_45der_45costa_45smeraldahK6rDHG9iuMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrten/costa-mittelmeer-kreuzfahrt-mit-der-costa-smeralda.vue?macro=true";
import { default as hurtigruten_45expeditionsbncIZyawGPMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrten/hurtigruten-expeditions.vue?macro=true";
import { default as indexoD4w8EmRrxMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrten/index.vue?macro=true";
import { default as explora_45journeysLEYCIQ9oqLMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/luxuskreuzfahrten/explora-journeys.vue?macro=true";
import { default as anmeldung_45bestaetigungc5lpF4RYWbMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/newsletter/anmeldung-bestaetigung.vue?macro=true";
import { default as anmeldung_45erfolgreichSrypNjcjVpMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/newsletter/anmeldung-erfolgreich.vue?macro=true";
import { default as anmeldung70BT2QK9G0Meta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/newsletter/anmeldung.vue?macro=true";
import { default as _91slug_93_45angeboteb17JQDsimVMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/pauschalreisen/[[level1]]/[[level2]]/[[level3]]/[[level4]]/[slug]-angebote.vue?macro=true";
import { default as hotels_45_91slug_93IguWgm9j16Meta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/pauschalreisen/[[level1]]/[[level2]]/[[level3]]/[[level4]]/hotels-[slug].vue?macro=true";
import { default as robinson_45premium_45cluburlaubnpwnprN4DnMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/pauschalreisen/cluburlaub/robinson-premium-cluburlaub.vue?macro=true";
import { default as suchebyyc7eawypMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/pauschalreisen/cluburlaub/suche.vue?macro=true";
import { default as sucheBqcfdIgWioMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/pauschalreisen/familienurlaub/suche.vue?macro=true";
import { default as sucheOGawlFyZXHMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/pauschalreisen/suche.vue?macro=true";
import { default as indexUqZOdOoZGRMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/ratgeber/index.vue?macro=true";
import { default as indexFWpua7cWUaMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/ratgeber/reiserecht/einreise/index.vue?macro=true";
import { default as jamaikaK8aJ3d9vgAMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/ratgeber/reiserecht/einreise/jamaika.vue?macro=true";
import { default as usa_45estacjs2tS56zrMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/ratgeber/reiserecht/einreise/usa-esta.vue?macro=true";
import { default as indexEkdm6O1f1mMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/ratgeber/reiserecht/index.vue?macro=true";
import { default as indexlNcP3aXh5VMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/service/haeufige-fragen/index.vue?macro=true";
import { default as flug_45gepaeck_45richtlinienw2gGKrMLKdMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/service/haeufige-fragen/reisen/flug-gepaeck-richtlinien.vue?macro=true";
import { default as mietwagen_45sunny_45cars_45angeboteClVaXjVID3Meta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/service/mietwagen-sunny-cars-angebote.vue?macro=true";
import { default as reiseberatungkRL987MF6mMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/service/reiseberatung.vue?macro=true";
import { default as reiseschutz_45von_45ergooyFEZbiMdFMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/service/reiseversicherungen/reiseschutz-von-ergo.vue?macro=true";
import { default as aegyptenF58pXev0HiMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/afrika/aegypten.vue?macro=true";
import { default as kapverdendpSDiDLHyXMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/afrika/kapverden.vue?macro=true";
import { default as marokkouRUYuaIjsCMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/afrika/marokko.vue?macro=true";
import { default as mauritiusDtrxCSoSoWMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/afrika/mauritius.vue?macro=true";
import { default as seychellenNA3Wqerr3JMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/afrika/seychellen.vue?macro=true";
import { default as tunesienVte8sCyGNyMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/afrika/tunesien.vue?macro=true";
import { default as malediven6jOnUhqxOzMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/asien/malediven.vue?macro=true";
import { default as thailandrAeUh3dyuIMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/asien/thailand.vue?macro=true";
import { default as australienvcteih2fCGMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/australien.vue?macro=true";
import { default as griechenlandGlERidehNGMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/griechenland.vue?macro=true";
import { default as indexi3fYbk72wVMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/index.vue?macro=true";
import { default as italienYJhYBb9M6EMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/italien.vue?macro=true";
import { default as kroatienJYVcm8QhyBMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/kroatien.vue?macro=true";
import { default as mexikoWQnGU5eYRJMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/mexiko.vue?macro=true";
import { default as portugalaknZoC8PTjMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/portugal.vue?macro=true";
import { default as ibizagCPWPKfazrMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/spanien/balearen/ibiza.vue?macro=true";
import { default as indexwZ6JpfVlFYMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/spanien/balearen/index.vue?macro=true";
import { default as mallorcaxcvGtkGdRiMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/spanien/balearen/mallorca.vue?macro=true";
import { default as fuerteventuradl8rkd6d87Meta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/spanien/kanaren/fuerteventura.vue?macro=true";
import { default as gran_45canariaWqnSkzvflAMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/spanien/kanaren/gran-canaria.vue?macro=true";
import { default as indexRCkekAG4CkMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/spanien/kanaren/index.vue?macro=true";
import { default as lanzaroteBc9f5X0hmFMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/spanien/kanaren/lanzarote.vue?macro=true";
import { default as teneriffaN14bdUCvL4Meta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/spanien/kanaren/teneriffa.vue?macro=true";
import { default as tuerkeiLcYnjefhtWMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/tuerkei.vue?macro=true";
import { default as usaTcNwCXskYwMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/usa.vue?macro=true";
import { default as indexKq2oRK8lQIMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/vereinigte-arabische-emirate/dubai/index.vue?macro=true";
import { default as zypernC88Cr31OrqMeta } from "/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/zypern.vue?macro=true";
export default [
  {
    name: "Downloadbereich Übersicht",
    path: "/3e403811-1d25-490c-bde7",
    meta: indexk36Kdzm4r2Meta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/(download-bereich)/3e403811-1d25-490c-bde7/index.vue").then(m => m.default || m)
  },
  {
    name: "Wunschtraumreise Anfrage erhalten",
    path: "/wunsch-traumreise-anfrage-erhalten",
    meta: wunsch_45traumreise_45anfrage_45erhaltenIeACYqrWoCMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/(forms)/wunsch-traumreise-anfrage-erhalten.vue").then(m => m.default || m)
  },
  {
    name: "Allgemeine Geschäftsbedingungen",
    path: "/agb",
    meta: agbU8ATZlKcAmMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/(legal)/agb.vue").then(m => m.default || m)
  },
  {
    name: "Datenschutz",
    path: "/datenschutz",
    meta: datenschutzkHFkt41CJUMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/(legal)/datenschutz.vue").then(m => m.default || m)
  },
  {
    name: "Haftungsausschluss",
    path: "/haftungsausschluss",
    meta: haftungsausschlussvmVl26DXOiMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/(legal)/haftungsausschluss.vue").then(m => m.default || m)
  },
  {
    name: "Impressum",
    path: "/impressum",
    meta: impressump8xuK3DSDLMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/(legal)/impressum.vue").then(m => m.default || m)
  },
  {
    name: "Nutzungsbedingungen",
    path: "/nutzungsbedingungen",
    meta: nutzungsbedingungen3PFiw8tyXXMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/(legal)/nutzungsbedingungen.vue").then(m => m.default || m)
  },
  {
    name: "Über uns",
    path: "/ueberuns",
    meta: ueberunsNmCQCi3d2QMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/(legal)/ueberuns.vue").then(m => m.default || m)
  },
  {
    name: "200",
    path: "/200",
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/(system)/200.vue").then(m => m.default || m)
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/(system)/404.vue").then(m => m.default || m)
  },
  {
    name: "error",
    path: "/error",
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/(system)/error.vue").then(m => m.default || m)
  },
  {
    name: "Status",
    path: "/status",
    meta: statusDpEcC5jykEMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/(system)/status.vue").then(m => m.default || m)
  },
  {
    name: "Ferienwohnungen",
    path: "/ferienwohnungen/suche",
    meta: suchedYSaGUMmE0Meta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/ferienwohnungen/suche.vue").then(m => m.default || m)
  },
  {
    name: "Hotelangebote",
    path: "/hotels/:slug()-angebote",
    meta: _91slug_93_45angeboteREDxkRHQGCMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/hotels/[slug]-angebote.vue").then(m => m.default || m)
  },
  {
    name: "Hotels zum verlieben",
    path: "/hotels/hotels-zum-verlieben",
    meta: hotels_45zum_45verliebenXnJJLx1Fr6Meta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/hotels/hotels-zum-verlieben.vue").then(m => m.default || m)
  },
  {
    name: "Hotels",
    path: "/hotels",
    meta: index9SRDJZzN3FMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/hotels/index.vue").then(m => m.default || m)
  },
  {
    name: "Home",
    path: "/",
    meta: indexPevFdO1h5RMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "Kreuzfahrt-Reederei AIDA",
    path: "/kreuzfahrt-reederei/aida-kreuzfahrten",
    meta: aida_45kreuzfahrten51789jhr4dMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-reederei/aida-kreuzfahrten.vue").then(m => m.default || m)
  },
  {
    name: "Kreuzfahrt-Reederei Carnival Cruise Line",
    path: "/kreuzfahrt-reederei/carnival-kreuzfahrten",
    meta: carnival_45kreuzfahrtenYK5q1Z8qUjMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-reederei/carnival-kreuzfahrten.vue").then(m => m.default || m)
  },
  {
    name: "Kreuzfahrt-Reederei Costa",
    path: "/kreuzfahrt-reederei/costa-kreuzfahrten",
    meta: costa_45kreuzfahrtenorMtjTsWIxMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-reederei/costa-kreuzfahrten.vue").then(m => m.default || m)
  },
  {
    name: "Kreuzfahrt-Reederei Explora Journeys",
    path: "/kreuzfahrt-reederei/explora-journeys-kreuzfahrten",
    meta: explora_45journeys_45kreuzfahrtenBxSVq1qHJrMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-reederei/explora-journeys-kreuzfahrten.vue").then(m => m.default || m)
  },
  {
    name: "Kreuzfahrt-Reederei MSC Cruises",
    path: "/kreuzfahrt-reederei/msc-kreuzfahrten",
    meta: msc_45kreuzfahrtenBgrahOi0fjMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-reederei/msc-kreuzfahrten.vue").then(m => m.default || m)
  },
  {
    name: "Kreuzfahrt-Reederei NCL",
    path: "/kreuzfahrt-reederei/ncl-kreuzfahrten",
    meta: ncl_45kreuzfahrtenp2e0QHgydaMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-reederei/ncl-kreuzfahrten.vue").then(m => m.default || m)
  },
  {
    name: "AIDAblu Schiffsüberblick",
    path: "/kreuzfahrt-schiffe/aidablu-kreuzfahrten",
    meta: aidablu_45kreuzfahrtenLNel0bCredMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-schiffe/aidablu-kreuzfahrten.vue").then(m => m.default || m)
  },
  {
    name: "AIDAcosma Schiffsüberblick",
    path: "/kreuzfahrt-schiffe/aidacosma-kreuzfahrten",
    meta: aidacosma_45kreuzfahrtenXDHl7rjpIdMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-schiffe/aidacosma-kreuzfahrten.vue").then(m => m.default || m)
  },
  {
    name: "AIDAnova Schiffsüberblick",
    path: "/kreuzfahrt-schiffe/aidanova-kreuzfahrten",
    meta: aidanova_45kreuzfahrtenRU9832QEFpMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-schiffe/aidanova-kreuzfahrten.vue").then(m => m.default || m)
  },
  {
    name: "AIDAperla Schiffsüberblick",
    path: "/kreuzfahrt-schiffe/aidaperla-kreuzfahrten",
    meta: aidaperla_45kreuzfahrtenIazRxxLGqaMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-schiffe/aidaperla-kreuzfahrten.vue").then(m => m.default || m)
  },
  {
    name: "AIDAprima Schiffsüberblick",
    path: "/kreuzfahrt-schiffe/aidaprima-kreuzfahrten",
    meta: aidaprima_45kreuzfahrtenuTshkk6K6ZMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-schiffe/aidaprima-kreuzfahrten.vue").then(m => m.default || m)
  },
  {
    name: "Costa Smeralda Schiffsüberblick",
    path: "/kreuzfahrt-schiffe/costa-smeralda-kreuzfahrten",
    meta: costa_45smeralda_45kreuzfahrtencBmcxvhy0YMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-schiffe/costa-smeralda-kreuzfahrten.vue").then(m => m.default || m)
  },
  {
    name: "Explora I Schiffsüberblick",
    path: "/kreuzfahrt-schiffe/explora-i-kreuzfahrten",
    meta: explora_45i_45kreuzfahrtenYeoF2A8IcMMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrt-schiffe/explora-i-kreuzfahrten.vue").then(m => m.default || m)
  },
  {
    name: "Kreuzfahrt Atlantischer Ozean mit AIDAcosma",
    path: "/kreuzfahrten/aida-kanaren-madeira-ab-gran-canaria-mit-aidacosma",
    meta: aida_45kanaren_45madeira_45ab_45gran_45canaria_45mit_45aidacosmai3HMOS2Fj0Meta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrten/aida-kanaren-madeira-ab-gran-canaria-mit-aidacosma.vue").then(m => m.default || m)
  },
  {
    name: "Kreuzfahrt Karibische Inseln mit AIDAperla",
    path: "/kreuzfahrten/aida-karibische-inseln-ab-barbados-mit-aidaperla",
    meta: aida_45karibische_45inseln_45ab_45barbados_45mit_45aidaperlaekEYbtEBBQMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrten/aida-karibische-inseln-ab-barbados-mit-aidaperla.vue").then(m => m.default || m)
  },
  {
    name: "Kreuzfahrt Indischer Ozean mit AIDAblu",
    path: "/kreuzfahrten/aida-mauritius-seychellen-madagaskar-mit-aidablu",
    meta: aida_45mauritius_45seychellen_45madagaskar_45mit_45aidabluUuv6Hr8BSOMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrten/aida-mauritius-seychellen-madagaskar-mit-aidablu.vue").then(m => m.default || m)
  },
  {
    name: "Kreuzfahrt Orient ab Dubai mit AIDAprima",
    path: "/kreuzfahrten/aida-orient-ab-dubai-mit-aidaprima",
    meta: aida_45orient_45ab_45dubai_45mit_45aidaprima3WDCCLFKf1Meta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrten/aida-orient-ab-dubai-mit-aidaprima.vue").then(m => m.default || m)
  },
  {
    name: "Mittelmeerkreuzfahrt Costa",
    path: "/kreuzfahrten/costa-mittelmeer-kreuzfahrt-mit-der-costa-smeralda",
    meta: costa_45mittelmeer_45kreuzfahrt_45mit_45der_45costa_45smeraldahK6rDHG9iuMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrten/costa-mittelmeer-kreuzfahrt-mit-der-costa-smeralda.vue").then(m => m.default || m)
  },
  {
    name: "Kreuzfahrten Hurtigruten Expeditions",
    path: "/kreuzfahrten/hurtigruten-expeditions",
    meta: hurtigruten_45expeditionsbncIZyawGPMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrten/hurtigruten-expeditions.vue").then(m => m.default || m)
  },
  {
    name: "Kreuzfahrten Home",
    path: "/kreuzfahrten",
    meta: indexoD4w8EmRrxMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/kreuzfahrten/index.vue").then(m => m.default || m)
  },
  {
    name: "Luxuskreuzfahrten Explora Journeys",
    path: "/luxuskreuzfahrten/explora-journeys",
    meta: explora_45journeysLEYCIQ9oqLMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/luxuskreuzfahrten/explora-journeys.vue").then(m => m.default || m)
  },
  {
    name: "Newsletter Anmeldung Bestätigung",
    path: "/newsletter/anmeldung-bestaetigung",
    meta: anmeldung_45bestaetigungc5lpF4RYWbMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/newsletter/anmeldung-bestaetigung.vue").then(m => m.default || m)
  },
  {
    name: "Newsletter Anmeldung erfolgreich",
    path: "/newsletter/anmeldung-erfolgreich",
    meta: anmeldung_45erfolgreichSrypNjcjVpMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/newsletter/anmeldung-erfolgreich.vue").then(m => m.default || m)
  },
  {
    name: "Newsletter Anmeldung",
    path: "/newsletter/anmeldung",
    meta: anmeldung70BT2QK9G0Meta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/newsletter/anmeldung.vue").then(m => m.default || m)
  },
  {
    name: "Pauschalreisen Specials",
    path: "/pauschalreisen/:level1?/:level2?/:level3?/:level4?/:slug()-angebote",
    meta: _91slug_93_45angeboteb17JQDsimVMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/pauschalreisen/[[level1]]/[[level2]]/[[level3]]/[[level4]]/[slug]-angebote.vue").then(m => m.default || m)
  },
  {
    name: "Pauschalreisen Hotels",
    path: "/pauschalreisen/:level1?/:level2?/:level3?/:level4?/hotels-:slug()",
    meta: hotels_45_91slug_93IguWgm9j16Meta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/pauschalreisen/[[level1]]/[[level2]]/[[level3]]/[[level4]]/hotels-[slug].vue").then(m => m.default || m)
  },
  {
    name: "ROBINSON Club Überblick",
    path: "/pauschalreisen/cluburlaub/robinson-premium-cluburlaub",
    meta: robinson_45premium_45cluburlaubnpwnprN4DnMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/pauschalreisen/cluburlaub/robinson-premium-cluburlaub.vue").then(m => m.default || m)
  },
  {
    name: "Cluburlaub Suche",
    path: "/pauschalreisen/cluburlaub/suche",
    meta: suchebyyc7eawypMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/pauschalreisen/cluburlaub/suche.vue").then(m => m.default || m)
  },
  {
    name: "Familienurlaub Suche",
    path: "/pauschalreisen/familienurlaub/suche",
    meta: sucheBqcfdIgWioMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/pauschalreisen/familienurlaub/suche.vue").then(m => m.default || m)
  },
  {
    name: "Pauschalreisen Suche",
    path: "/pauschalreisen/suche",
    meta: sucheOGawlFyZXHMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/pauschalreisen/suche.vue").then(m => m.default || m)
  },
  {
    name: "Ratgeber",
    path: "/ratgeber",
    meta: indexUqZOdOoZGRMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/ratgeber/index.vue").then(m => m.default || m)
  },
  {
    name: "Einreise",
    path: "/ratgeber/reiserecht/einreise",
    meta: indexFWpua7cWUaMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/ratgeber/reiserecht/einreise/index.vue").then(m => m.default || m)
  },
  {
    name: "Einreise nach Jamaika per Flug oder Schiff",
    path: "/ratgeber/reiserecht/einreise/jamaika",
    meta: jamaikaK8aJ3d9vgAMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/ratgeber/reiserecht/einreise/jamaika.vue").then(m => m.default || m)
  },
  {
    name: "Einreise in die USA (ESTA)",
    path: "/ratgeber/reiserecht/einreise/usa-esta",
    meta: usa_45estacjs2tS56zrMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/ratgeber/reiserecht/einreise/usa-esta.vue").then(m => m.default || m)
  },
  {
    name: "Reiserecht",
    path: "/ratgeber/reiserecht",
    meta: indexEkdm6O1f1mMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/ratgeber/reiserecht/index.vue").then(m => m.default || m)
  },
  {
    name: "Häufig gestellte Fragen (FAQ)",
    path: "/service/haeufige-fragen",
    meta: indexlNcP3aXh5VMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/service/haeufige-fragen/index.vue").then(m => m.default || m)
  },
  {
    name: "Fluggepäckrichtlinien",
    path: "/service/haeufige-fragen/reisen/flug-gepaeck-richtlinien",
    meta: flug_45gepaeck_45richtlinienw2gGKrMLKdMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/service/haeufige-fragen/reisen/flug-gepaeck-richtlinien.vue").then(m => m.default || m)
  },
  {
    name: "Sunny Cars Mietwagen",
    path: "/service/mietwagen-sunny-cars-angebote",
    meta: mietwagen_45sunny_45cars_45angeboteClVaXjVID3Meta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/service/mietwagen-sunny-cars-angebote.vue").then(m => m.default || m)
  },
  {
    name: "Reiseberatung",
    path: "/service/reiseberatung",
    meta: reiseberatungkRL987MF6mMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/service/reiseberatung.vue").then(m => m.default || m)
  },
  {
    name: "Versicherungen von ERGO",
    path: "/service/reiseversicherungen/reiseschutz-von-ergo",
    meta: reiseschutz_45von_45ergooyFEZbiMdFMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/service/reiseversicherungen/reiseschutz-von-ergo.vue").then(m => m.default || m)
  },
  {
    name: "Ägypten",
    path: "/urlaub/afrika/aegypten",
    meta: aegyptenF58pXev0HiMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/afrika/aegypten.vue").then(m => m.default || m)
  },
  {
    name: "Kapverden",
    path: "/urlaub/afrika/kapverden",
    meta: kapverdendpSDiDLHyXMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/afrika/kapverden.vue").then(m => m.default || m)
  },
  {
    name: "Marokko",
    path: "/urlaub/afrika/marokko",
    meta: marokkouRUYuaIjsCMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/afrika/marokko.vue").then(m => m.default || m)
  },
  {
    name: "Mauritius",
    path: "/urlaub/afrika/mauritius",
    meta: mauritiusDtrxCSoSoWMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/afrika/mauritius.vue").then(m => m.default || m)
  },
  {
    name: "Seychellen",
    path: "/urlaub/afrika/seychellen",
    meta: seychellenNA3Wqerr3JMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/afrika/seychellen.vue").then(m => m.default || m)
  },
  {
    name: "Tunesien",
    path: "/urlaub/afrika/tunesien",
    meta: tunesienVte8sCyGNyMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/afrika/tunesien.vue").then(m => m.default || m)
  },
  {
    name: "Malediven",
    path: "/urlaub/asien/malediven",
    meta: malediven6jOnUhqxOzMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/asien/malediven.vue").then(m => m.default || m)
  },
  {
    name: "Thailand",
    path: "/urlaub/asien/thailand",
    meta: thailandrAeUh3dyuIMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/asien/thailand.vue").then(m => m.default || m)
  },
  {
    name: "Australien",
    path: "/urlaub/australien",
    meta: australienvcteih2fCGMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/australien.vue").then(m => m.default || m)
  },
  {
    name: "Griechenland",
    path: "/urlaub/griechenland",
    meta: griechenlandGlERidehNGMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/griechenland.vue").then(m => m.default || m)
  },
  {
    name: "Urlaub",
    path: "/urlaub",
    meta: indexi3fYbk72wVMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/index.vue").then(m => m.default || m)
  },
  {
    name: "Italien",
    path: "/urlaub/italien",
    meta: italienYJhYBb9M6EMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/italien.vue").then(m => m.default || m)
  },
  {
    name: "Kroatien",
    path: "/urlaub/kroatien",
    meta: kroatienJYVcm8QhyBMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/kroatien.vue").then(m => m.default || m)
  },
  {
    name: "Mexiko",
    path: "/urlaub/mexiko",
    meta: mexikoWQnGU5eYRJMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/mexiko.vue").then(m => m.default || m)
  },
  {
    name: "Portugal",
    path: "/urlaub/portugal",
    meta: portugalaknZoC8PTjMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/portugal.vue").then(m => m.default || m)
  },
  {
    name: "Ibiza",
    path: "/urlaub/spanien/balearen/ibiza",
    meta: ibizagCPWPKfazrMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/spanien/balearen/ibiza.vue").then(m => m.default || m)
  },
  {
    name: "Balearen",
    path: "/urlaub/spanien/balearen",
    meta: indexwZ6JpfVlFYMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/spanien/balearen/index.vue").then(m => m.default || m)
  },
  {
    name: "Mallorca",
    path: "/urlaub/spanien/balearen/mallorca",
    meta: mallorcaxcvGtkGdRiMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/spanien/balearen/mallorca.vue").then(m => m.default || m)
  },
  {
    name: "Fuerteventura",
    path: "/urlaub/spanien/kanaren/fuerteventura",
    meta: fuerteventuradl8rkd6d87Meta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/spanien/kanaren/fuerteventura.vue").then(m => m.default || m)
  },
  {
    name: "Gran Canaria",
    path: "/urlaub/spanien/kanaren/gran-canaria",
    meta: gran_45canariaWqnSkzvflAMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/spanien/kanaren/gran-canaria.vue").then(m => m.default || m)
  },
  {
    name: "Kanaren",
    path: "/urlaub/spanien/kanaren",
    meta: indexRCkekAG4CkMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/spanien/kanaren/index.vue").then(m => m.default || m)
  },
  {
    name: "Lanzarote",
    path: "/urlaub/spanien/kanaren/lanzarote",
    meta: lanzaroteBc9f5X0hmFMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/spanien/kanaren/lanzarote.vue").then(m => m.default || m)
  },
  {
    name: "Teneriffa",
    path: "/urlaub/spanien/kanaren/teneriffa",
    meta: teneriffaN14bdUCvL4Meta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/spanien/kanaren/teneriffa.vue").then(m => m.default || m)
  },
  {
    name: "Türkei",
    path: "/urlaub/tuerkei",
    meta: tuerkeiLcYnjefhtWMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/tuerkei.vue").then(m => m.default || m)
  },
  {
    name: "USA",
    path: "/urlaub/usa",
    meta: usaTcNwCXskYwMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/usa.vue").then(m => m.default || m)
  },
  {
    name: "Urlaub Dubai",
    path: "/urlaub/vereinigte-arabische-emirate/dubai",
    meta: indexKq2oRK8lQIMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/vereinigte-arabische-emirate/dubai/index.vue").then(m => m.default || m)
  },
  {
    name: "Zypern",
    path: "/urlaub/zypern",
    meta: zypernC88Cr31OrqMeta || {},
    component: () => import("/home/skorn/git.root/cf-web-static-nuxt-costeri/src/pages/urlaub/zypern.vue").then(m => m.default || m)
  }
]