export default defineAppConfig({
  isDevMode: process.env.NODE_ENV === 'development',

  mission: 'Continent Ventures',
  baseDomain: 'https://www.costeri.de',
  spacingYPage: 'space-y-10 sm:space-y-20 xl:space-y-40',
  spacingYContent: 'space-y-10',

  company: {
    /** Short company name */
    name: 'Costéri',
    nameAfter: 'Travel-Tours',
    /** Full company name without legal entity type */
    nameFull: 'Costéri Travel-Tours',
    /** Full legal company name */
    nameOfficial: 'Costéri Travel-Tours GmbH',
    phoneNumber: '0621 48 343 300',
    phoneNumberCruises: '0621 48343-300',
    phoneNumberCruisesInternational: '+49 (0) 621 48343300',
    faxNumber: '0621 48 343 302',
    email: 'hallo@costeri.de',
    emailReisen: 'reisen@costeri.de',
    infoTag: 'Persönliche Beratung und Buchung',
    // openingHoursLong: 'Montag bis Samstag von 10:00 Uhr bis 19:00 Uhr',
    openingHoursLong: 'Mo. bis Sa. von 10 Uhr bis 19 Uhr',
    openingHours: 'Mo-Sa: 10:00 - 19:00 Uhr',
    addressStreet: 'Theodor-Heuss-Anlage 12',
    addressZip: '68165',
    addressCity: 'Mannheim',
    addressCountry: 'Deutschland',
    gdprMail: 'datenschutz@costeri.de',
    generalManager1: 'Stefan Korn',
    generalManager1JobTitle: 'Gründer & Geschäftsführer',
    headOfCustomerService: 'Diana Bahr',
    headOfCustomerServiceJobTitle: 'Leiterin Kundenbetreuung',
  },

  googleAdsenseUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2814605353778133',
  imgCDN: {
    /** imgIX Image CDN */
    // imgIX: https://cx-files.imgix.net
    // https://docs.imgix.com/apis/rendering
    // url: "https://assets.costeri.de/",
    url: "https://cx-files.imgix.net/",
    params: '?auto=compress&auto=format&fit=crop&crop=focalpoint',
  },

})
