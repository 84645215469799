    <!-- <NuxtRouteAnnouncer /> -->
<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
const config = useAppConfig()

// if (!route.meta.hideChat) {
//   useHead(() => ({
//     script: [
//       {
//         async: true,
//         src: 'https://embed.tawk.to/65d3dd079131ed19d96ec5a0/1hn1peq2i',
//         tagPosition: 'bodyClose',
//       },
//     ]
//   }))
// }

defineOgImage({ url: '/images/costeri_website.png', width: 1886, height: 1130, alt: 'Reisebüro Costéri' })

useHead(() => ({
  titleTemplate: '%s %separator %company',
  templateParams: { company: config.company.name },
}))
</script>
